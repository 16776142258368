
<template>
    <div style="margin:5px">
        <template v-if="loadedsearch">
            
            <div class="qmajorheader">{{ langConv('Search Results') }} for {{searchdesctitle}}</div>
            <div class="qsubheader">{{ langConv('Number of Results') }} {{searchresults[0].h.norecs}} </div>
            <br/>
            <div v-for="(row,i) in searchresults[0].BO" :key="i">

                <div>
                    <div v-if="title(row.urlstub) != ''">
                        {{currenttitle}}
                        <hr>
                    </div>
                    <a class="qmediumfont qlink" :href="$URLCreateTS(row.urlstub, row.sport, row.country, row.league, row.event, row.bettype, row.entityid)">
                       <div><span v-html="$spaceandclean(row.league)"></span><span v-html="$spaceandclean(row.event)"></span>
                        </div>
                    </a>
                        <div class="qmediumfont"><span v-html="$spaceandclean(row.sport)"></span><span>{{row.county}}</span></div>
                        <div class="qmediumfont"><span v-html="$spaceandclean(row.bettype)"></span></div>
                    <br/>
                </div>

            </div>
        </template>
    </div>
</template>


<script>
import axios from "axios";
    export default {

        name: "searchresults",
        components: {

        },
        data() {
            return {
                searchresults: [],
                loadedsearch: false,
                currenttitle: "",
                searchdesctitle: ""
            }
        },
        watch: {
            '$route.path': {
                handler (oldUrl, newUrl) {
                    this.setdata();
                }
            }
        },
        
        mounted() {
            this.setdata();
            
        },
        props: {
            searchdesc: String
        },
        methods: {

            setdata: function() {
                this.searchdesctitle = this.searchdesc;
                this.searchresultsData(this.searchdesc);
            },

            //new title for search
            title: function (val) {
                if (val != this.currenttitle) {
                    this.currenttitle = val;
                    return val;
                } else {
                    return ""
                }
            },


            // //get full data for search results
            searchresultsData: function () {

                var URL = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/SearchResults";

                axios.get(URL, {

                    params: {
                        desc: this.searchdesc,//parseInt(this.id), //can get multiple banner sizes by passing the banner number into the array
                        noitems: 100, //parseInt(this.noitems), // does nothing at the moment 18/01/2019
                        wildcard: true,
                        sportid: -1
                    }

                }).then(response => {

                    this.searchresults = JSON.parse(response.data);
                    this.loadedsearch = true;

                    },
                        (error) => { console.log(error) }
                    );
            }

        }
    }

</script>

<style scoped>
</style>